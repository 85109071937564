import Btn from '@/components/Btn/Btn.vue';
import Permissions from '@/components/Permissions/Permissions.vue';
import ROLES_QUERY from '@/graphql/queries/roles.gql';
import CLIENTS_QUERY from '@/graphql/queries/clients.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import ROLES_WITH_PERMISSIONS from '@/graphql/queries/rolesWithPermissions.gql';
import validateRules from '@/services/validateRules';
import { sortByName } from '@/utils/sortByName';

export default {
  name: 'UserForm',
  components: { Btn, Permissions },
  apollo: {
    clients: {
      query: CLIENTS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load client list.' }
          : error;
      },
      update(data) {
        return data.clients.sort(sortByName);
      },
    },
    roles: {
      query: ROLES_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load role list.' }
          : error;
      },
      update(data) {
        return data.roles.sort(sortByName);
      },
    },
    rolesWithPermissions: {
      query: ROLES_WITH_PERMISSIONS,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load permissions list.' }
          : error;
      },
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  props: {
    btnLabel: { type: String, required: true },
    loading: { type: Boolean, default: false },
    user: { type: Object },
  },
  data: () => ({
    currentUser: null,
    rolesWithPermissions: null,
    usernameRules: [v => !!v || 'Username is Required'],
    emailRules: [v => !!v || 'Email is Required', validateRules.email],
    roleRules: [v => !!v || 'Role is Required'],
    roles: [],
    clientRules: [v => !!v || 'Client is Required'],
    clients: [],
    error: null,
  }),
  watch: {
    'user.role'(val, oldVal) {
      if (!oldVal || val.id !== oldVal.id) {
        this.user.client = null;
        const roleWithPermission =
          this.user.role &&
          this.rolesWithPermissions &&
          this.rolesWithPermissions.find(r => this.user.role.name === r.name);
        if (roleWithPermission) {
          this.user.permissions = roleWithPermission.permissions.map(i => ({ group: i.group, key: i.key }));
        }
      }
    },
  },
  computed: {
    showClients() {
      return this.user.role && (this.user.role.name === 'user' || this.user.role.name === 'viewer');
    },
    showPermissionsSection() {
      return accessByPermissions(PERMISSIONS.CHANGE_USER_PERMISSIONS, this.currentUser);
    },
    displayedRoles() {
      const canAddAdminUser = accessByPermissions(PERMISSIONS.ADD_ADMIN, this.currentUser);
      return canAddAdminUser ? this.roles : this.roles.filter(r => r.name !== 'admin');
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        this.$emit('submit', this.user);
      }
    },
  },
};
