var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"user-details-form",attrs:{"mutation":_vm.UPDATE_USER_MUTATION,"variables":{
    input: {
      id: _vm.user.id,
      clientId: _vm.user.client ? _vm.user.client.id || _vm.user.client : undefined,
      username: _vm.user.username,
      email: _vm.user.email,
      roleId: _vm.user.role ? _vm.user.role.id : undefined,
      permissions: _vm.user.permissions || undefined,
    },
  },"update":_vm.updateUsers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('user-form',_vm._b({on:{"submit":function($event){return mutate()}}},'user-form',{
        user: _vm.user,
        btnLabel: 'Update',
        loading: loading,
      },false))]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }